<template>
  <div v-if="model">
    <section v-if="model.image && model.image.length" class="fourSquareMain">
      <header>
        <div>
          <div class="userInfo">
            <img :src="model.profileImage" alt />
            <div class="userName">
              {{model.fullName && model.fullName.length && model.fullName.split(' ')[0] ? model.fullName.split(' ')[0] : ' '}}
              <span>{{model.fullName && model.fullName.length && model.fullName.split(' ')[1] ? model.fullName.split(' ')[1] : ' '}}</span>
            </div>
          </div>
          <div class="accountInfo">
            <div>{{accountTitle}}</div>
            <img src="/images/foursquare/foursquare_icon.png" alt />
          </div>
        </div>
      </header>

      <main>
        <dynamic-template name="BackgroundImage" class="social-image wow fadeInLeft"></dynamic-template>
        <div v-if="model.comment && model.comment.length" class="comment">{{model.comment}}</div>
      </main>
    </section>

    <div v-if="!model.image || model.image.length==0" class="tipMain">
      <div class="tipDetails wow fadeInLeft">
        <h1>
          {{model.fullName && model.fullName.length && model.fullName.split(' ')[0] ? model.fullName.split(' ')[0] : ' '}}
          <span>{{model.fullName && model.fullName.length && model.fullName.split(' ')[1] ? model.fullName.split(' ')[1] : ' '}}</span>
        </h1>
        <h2>{{model.comment}}</h2>
        <h3>{{accountTitle}}</h3>
      </div>
      <img :src="model.profileImage" alt />
    </div>
  </div>
</template>

<script>
import WOW from "wow.js";
import accountStorage from "../storage/modules/account";
import jwtHelper from "../shared/jwt-helper";
import store from "../store/index";
import DynamicTemplate from "../components/DynamicTemplate";
new WOW().init();
export default {
  name: "foursquare-tip",
  props: ["data"],
  components: { DynamicTemplate },
  computed: {
    model() {
      return this.$store.state.foursquareTip;
    },
  },
  async created() {
    var accountId = jwtHelper.getAccountId();
    var account = await accountStorage.getAccountById(accountId);
    this.accountTitle = account ? account.title : "";
    if (this.model && (!this.model.image || !this.model.image.length)) {
      setTimeout(() => {
        store.dispatch("setNextPlaylist");
      }, 15 * 1000);
    }
  },
  data() {
    return {
      accountTitle: "",
    };
  },
};
</script>


<style lang="scss" scoped>
@import "../assets/animate/animate.css";
.fourSquareMain {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  margin: auto;
}

.fourSquareMain header > div {
  height: 230px;
  width: 100%;
  background-image: url(/images/foursquare/header.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.fourSquareMain .userName {
  padding-top: 50px;
  font-size: 55px;
  color: white;
}

.fourSquareMain .userName span {
  font-weight: 700;
}

.fourSquareMain .userInfo {
  width: 60%;
}

.fourSquareMain .accountInfo {
  width: 40%;
  text-align: center;
  color: white;
}

.fourSquareMain .accountInfo > div {
  font-size: 40px;
  margin: 30px;
}

.fourSquareMain div.userInfo > img {
  width: 175px;
  height: 175px;
  border: 5px solid white;
  border-radius: 50%;
  float: left;
  margin-left: 255px;
  margin-right: 15px;
  margin-top: 12px;
}

.fourSquareMain .comment {
  position: fixed;
  width: 1000px;
  min-height: 100px;
  max-height: 600px;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: auto;
  color: white;
  background: url(/images/foursquare/text_area.png);
  font-size: 35px;
  padding: 25px;
  line-height: 140%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.fourSquareMain main {
  position: fixed;
  top: 230px;
  width: 100%;
  height: calc(100% - 230px);
}

.tipMain {
  background-image: url("/images/foursquare/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-items: center;
  position: fixed;
  .tipDetails {
    h1 {
      margin-top: 190px;
      color: white;
      letter-spacing: 2;
      font-size: 80px;
      font-family: "Montserrat-light";

      span {
        font-weight: bolder;
        font-family: "Montserrat-regular";
      }
    }
    h2 {
      width: 45%;
      margin: auto;
      font-size: 42px;
      line-height: 1.2;
      color: white;
      margin-top: 0;
      font-weight: lighter;
      height: 365px;
      overflow: hidden;
      padding-top: 75px;
      font-family: "Montserrat-light";
    }
    h3 {
      font-size: 58px;
      color: white;
      width: 375px;
      height: 200px;
      overflow: hidden;
      margin: auto;
      margin-top: 30px;
    }
  }
  img {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    left: 270px;
    margin-top: -300px;
    border: 5px solid white;
  }
}
</style>